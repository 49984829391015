import React, {useContext} from "react";
import "./Footer.scss";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function Footer() {
  const {isDark} = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <p
          className={isDark ? "dark-mode footer-text" : "footer-text"}
          style={{whiteSpace: "pre"}}
        >
          Copyright{"   "}
          <span className="icon">
            <i className="far fa-copyright"></i>
          </span>
          {"   "}
          2024 WowYiJiu
          <br></br>
          <a
            className="github"
            href="https://github.com/WowYiJiu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.shields.io/badge/Source-Github-black?logo=github"
              title="本站项目由 GitHub 托管"
              alt="GitHub"
            />
          </a>
          {"  "}
          <a
            className="homepage"
            href="https://nav.wowyijiu.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.shields.io/badge/Personal-homepage-blue?logo=homepage"
              title="个人导航页"
              alt="Homepage"
            />
          </a>
          {"  "}
          <a
            className="service"
            href="https://status.wowyijiu.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.shields.io/badge/Service-Status-green?logo=uptimekuma"
              title="服务运行状态"
              alt="Service Status"
            />
          </a>
          {"  "}
          <a
            className="service"
            href="https://monitor.wowyijiu.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.shields.io/badge/NeZha-Status-%2300CAFF?logo=amazoncloudwatch"
              title="哪吒监控"
              alt="VPS Status"
            />
          </a>
        </p>
      </div>
    </Fade>
  );
}
